import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './router'
import stores from './store'
import Vuex from 'vuex'
import './static/css/reset.less'



Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuex);


const router = new VueRouter({
  mode: 'hash', // 或 'hash'
  routes
})
const store = new Vuex.Store(stores)

// Vue.use()

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
