

export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/index.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about.vue')
    },
    {
        path: '/products',
        // name: 'productsIndex',
        component: () => import('@/views/products/index.vue'),
        children: [
            {
                path: '',
                name: 'products',
                component: () => import('@/views/products/productList.vue')
            },
            {
                path: ':id',
                name: 'productDetail',
                component: () => import('@/views/products/productDetail.vue')
            }
        ]
    },
    {
        path: '/news',
        // name: 'newsIndex',
        component: () => import('@/views/news/index.vue'),
        children: [
            {
                path: '',
                name: 'news',
                component: () => import('@/views/news/list.vue')
            }
        ]
    }
]