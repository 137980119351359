<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import PageBody from './components/pageBody.vue'

export default {
  name: 'App',
  components: {
    // PageBody
  }
}
</script>

<style>
#app {
  
}
</style>
